// =======================================================
// Original Bootstrap Variable
// =======================================================

// Color system

$white:    #fff !default;
$gray-100: #f6f6f6 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #e0e0e0 !default;
$gray-500: #adb5bd !default;
$gray-600: #666666 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1b1b1b !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #4285f4 !default;
$indigo:  #6c63ff !default;
$purple:  #7952B3 !default;
$pink:    #d6456a !default;
$red:     #d72924 !default;
$orange:  #ff6e0d !default;
$yellow:  #ffc107 !default;
$green:   #00b191 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

// primary Theme color
$primary:       $indigo !default;
$secondary:     $black !default;
$dark:          $gray-900 !default;
$light:         $gray-100 !default;

// Other color
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;

$theme-colors: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "pink":       $pink,
    "purple":     $purple
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
) !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                false !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-900 !default;


// Links
//
// Style anchor elements.

$link-color:                              theme-color("primary") !default;
$link-decoration:                         none !default;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 560px,
  md: 750px,
  lg: 970px,
  xl: 1170px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

$embed-responsive-aspect-ratios: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 1.5 !default;
$h2-font-size:                $font-size-base * 1.375 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base * 1.125 !default;
$h5-font-size:                $font-size-base !default;
$h6-font-size:                $font-size-base * .875 !default;

$headings-margin-bottom:      .75rem !default;
$headings-font-family:        null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

$display1-size:               3.6rem !default;
$display2-size:               2.4rem !default;
$display3-size:               2.0rem !default;
$display4-size:               1.8rem !default;

$display1-weight:             400 !default;
$display2-weight:             400 !default;
$display3-weight:             400 !default;
$display4-weight:             400 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-small-font-size:  $small-font-size !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

$hr-margin-y:                 $spacer !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-color:                 $body-color !default;
$table-bg:                    null !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($black, .075) !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          $border-width !default;
$table-border-color:          $border-color !default;

$table-head-bg:               $gray-200 !default;
$table-head-color:            $gray-700 !default;

$table-dark-color:            $white !default;
$table-dark-bg:               $gray-800 !default;
$table-dark-accent-bg:        rgba($white, .05) !default;
$table-dark-hover-color:      $table-dark-color !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
$table-dark-color:            $white !default;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;

$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .75rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base * .875 !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       0 !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


// Forms

$label-margin-bottom:                   .5rem !default;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-200 !default;

$input-color:                           $gray-700 !default;
$input-border-color:                    $gray-400 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   0 !default;
$input-border-radius-lg:                0 !default;
$input-border-radius-sm:                0 !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              lighten($component-active-bg, 25%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-600 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:                  .25rem !default;

$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .3rem !default;
$form-check-input-margin-x:             .25rem !default;

$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-grid-gutter-width:                10px !default;
$form-group-margin-bottom:              1.5rem !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-200 !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter:                 .5rem !default;
$custom-control-spacer-x:               1rem !default;
$custom-control-cursor:                 null !default;

$custom-control-indicator-size:         1rem !default;
$custom-control-indicator-bg:           $input-bg !default;

$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color:            null !default;

$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
$custom-control-label-disabled-color:           $gray-600 !default;

$custom-control-indicator-checked-color:        $component-active-color !default;
$custom-control-indicator-checked-bg:           $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default;
$custom-control-indicator-checked-box-shadow:   none !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color:   $input-focus-border-color !default;

$custom-control-indicator-active-color:         $component-active-color !default;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow:    none !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius:       0 !default;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:   none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2 !default;
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

$custom-select-padding-y:           $input-padding-y !default;
$custom-select-padding-x:           $input-padding-x !default;
$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight !default;
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $gray-200 !default;
$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800 !default;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       0 !default;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm:        $input-padding-y-sm !default;
$custom-select-padding-x-sm:        $input-padding-x-sm !default;
$custom-select-font-size-sm:        $input-font-size-sm !default;
$custom-select-height-sm:           $input-height-sm !default;

$custom-select-padding-y-lg:        $input-padding-y-lg !default;
$custom-select-padding-x-lg:        $input-padding-x-lg !default;
$custom-select-font-size-lg:        $input-font-size-lg !default;
$custom-select-height-lg:           $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $gray-300 !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:                   1rem !default;
$custom-range-thumb-height:                  $custom-range-thumb-width !default;
$custom-range-thumb-bg:                      $component-active-bg !default;
$custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           1rem !default;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg:             $gray-500 !default;

$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-focus-box-shadow !default;
$custom-file-disabled-bg:           $input-disabled-bg !default;

$custom-file-padding-y:             $input-padding-y !default;
$custom-file-padding-x:             $input-padding-x !default;
$custom-file-line-height:           $input-line-height !default;
$custom-file-font-family:           $input-font-family !default;
$custom-file-font-weight:           $input-font-weight !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;


// Form validation

$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;
$form-feedback-valid-color:         theme-color("success") !default;
$form-feedback-invalid-color:       theme-color("danger") !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// Navs

$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            0 !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           0 !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              $spacer / 2 !default;


// Navbar

$navbar-padding-y:                  $spacer / 2 !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-dark-color:                 rgba($white, .9) !default;
$navbar-dark-hover-color:           $white !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                rgba($dark, .9) !default;
$navbar-light-hover-color:          rgba($dark, .7) !default;
$navbar-light-active-color:         $primary !default;
$navbar-light-disabled-color:       rgba($dark, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color:                $navbar-light-active-color !default;
$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                12.5rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                .9375rem !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            0 !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $gray-200 !default;
$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:            $gray-100 !default;

$dropdown-link-active-color:        $primary !default;
$dropdown-link-active-bg:           $gray-100 !default;

$dropdown-link-disabled-color:      $gray-600 !default;

$dropdown-item-padding-y:           .5rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $gray-600 !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;


// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $link-color !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           $gray-300 !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               $gray-200 !default;
$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;


// Jumbotron

$jumbotron-padding:                 2rem !default;
$jumbotron-color:                   null !default;
$jumbotron-bg:                      $gray-200 !default;


// Cards

$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.25rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                0 !default;
$card-border-color:                 $border-color !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 $grid-gutter-width / 2 !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;


// Tooltips

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 .25rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   $line-height-base !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;


// Popovers

$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              rgba($black, .2) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg:                 darken($popover-bg, 3%) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;


// Toasts

$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .25rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            rgba($white, .85) !default;
$toast-border-width:                1px !default;
$toast-border-color:                rgba(0, 0, 0, .1) !default;
$toast-border-radius:               .25rem !default;
$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;

$toast-header-color:                $gray-600 !default;
$toast-header-background-color:     rgba($white, .85) !default;
$toast-header-border-color:         rgba(0, 0, 0, .05) !default;


// Badges

$badge-font-size:                   75% !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-padding-y:                   .25em !default;
$badge-padding-x:                   .4em !default;
$badge-border-radius:               0 !default;

$badge-transition:                  $btn-transition !default;
$badge-focus-width:                 $input-btn-focus-width !default;

$badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               1rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       .5rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-border-color:         $border-color !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1rem !default;
$modal-header-padding-x:            1rem !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                $border-width !default;

$alert-bg-level:                    -10 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 6 !default;


// Progress bars

$progress-height:                   1rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       $gray-200 !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   theme-color("primary") !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;


// List group

$list-group-color:                  null !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          0 !default;

$list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:         1.25rem !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-700 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;


// Image thumbnails

$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            $gray-300 !default;
$thumbnail-border-radius:           0 !default;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) !default;


// Figures

$figure-caption-font-size:          90% !default;
$figure-caption-color:              $gray-600 !default;


// Breadcrumbs

$breadcrumb-font-size:              null !default;

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     $gray-200 !default;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          $border-radius !default;


// Carousel

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;

$carousel-control-icon-width:        20px !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

// Close

$close-font-size:                   $font-size-base * 1.5 !default;
$close-font-weight:                 $font-weight-bold !default;
$close-color:                       $black !default;
$close-text-shadow:                 0 1px 0 $white !default;

// Code

$code-font-size:                    87.5% !default;
$code-color:                        $pink !default;

$kbd-padding-y:                     .2rem !default;
$kbd-padding-x:                     .4rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $white !default;
$kbd-bg:                            $gray-900 !default;

$pre-color:                         $gray-900 !default;
$pre-scrollable-max-height:         340px !default;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
$user-selects: all, auto, none !default;


// Printing

$print-page-size:                   a3 !default;
$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;

// =======================================================
// Theme Variables Start
// =======================================================

//body fonts
$wrapper-font-size:                 $font-size-base * .9375 !default;
$font_1:                            "Open Sans" !default;
$font_2:                            sans-serif !default;

// heading font
$font_3:                            "Roboto" !default;
$font_4:                            sans-serif !default;

//Primary color can editing in line 67 to 77

// theme color
$color_fonts:                        $gray-900 !default;
$color_p:                            $gray-600 !default;
$color_post:                         $gray-600 !default;
$color_a:                            $primary !default;

// for dark footer
$color_dark_footer:                  $gray-300 !default;

// for light footer
$color_light_footer:                 $gray-600 !default;

// social color
$color_facebook:    #3b5898 !default;
$color_twitter:     #55aced !default;
$color_instagram:   #e1306c !default;
$color_youtube:     #e02a21 !default;
$color_vimeo:       #7ac5f2 !default;
$color_envelope:    #f2610b !default;
$color_linkedin:    #3371b7 !default;
$color_telegram:    #1187cc !default;
$color_pinterest:   #c9261a !default;
$color_vk:          #5a7fa6 !default;

$socialcolors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$socialcolors: map-merge(
  (
    "facebook":       $color_facebook,
    "twitter":        $color_twitter,
    "instagram":      $color_instagram,
    "youtube":        $color_youtube,
    "vimeo":          $color_vimeo,
    "envelope":       $color_envelope,
    "linkedin":       $color_linkedin,
    "telegram":       $color_telegram,
    "pinterest":      $color_pinterest,
    "vk":             $color_vk
  ),
  $socialcolors
);

$morecolors: () !default;
// other theme colors
$morecolors: map-merge(
  (
    "blue":       $blue,
    "purple":     $purple,
    "pink":       $pink,
    "yellow":     $yellow,
    "black":      $black
  ),
  $morecolors
);

// background menu hover
$wild_sand:         $gray-100 !default;

// opacity
$opacity-1:          1 !default;
$opacity-09:        .9 !default;
$opacity-08:        .8 !default;
$opacity-07:        .7 !default;
$opacity-06:        .6 !default;
$opacity-05:        .5 !default;
$opacity-04:        .4 !default;
$opacity-03:        .3 !default;
$opacity-02:        .2 !default;
$opacity-01:        .1 !default;
$opacity-0:          0 !default;

// block title rem
$two-rem:            2rem !default;
$one-half-rem:       1.5rem !default;
$one-rem:            1rem !default;
$half-rem:           .5rem !default;
$quarter-rem:        .25rem !default;
$block-margin-bottom:     1.5rem !default;
$block-button-bottom:     .5rem !default;
$margin-module:           2rem !default;

//background block title urls
$url_0:              url(../img/assets/stripe.png) !default;
$url_1:              url(../img/assets/dot.png) !default;
                                    
// border custom dropdown
$dropdown-border-custom:                    3px 0 0 !default;

//line height
$title-line-height:                         1.5rem !default;

// font weight
$font-weight-500:                           500 !default;
$font-weight-600:                           600 !default;
$font-weight-800:                           800 !default;
$font-weight-900:                           900 !default;

// font size
$font-size-1:                               $font-size-base !default;
$font-size-2:                               $font-size-base * 2 !default;
$font-size-3:                               $font-size-base * 3 !default;
$font-size-4:                               $font-size-base * 4 !default;
$font-size-5:                               $font-size-base * 5 !default;

// z-index
$z-index-5:                                 5 !default;
$z-index-10:                                10 !default;
$z-index-20:                                20 !default;                  
$z-index-50:                                50 !default;
$z-index-100:                               100 !default;
$z-index-500:                               500 !default;
$z-index-1000:                              1000 !default;

// blockquote
$border-left-blockquote:                    $primary !default;
$border-left-width-blockquote:              3px !default;

$main-logo-margin-top:                      1rem !default;
$main-logo-margin-bottom:                   1rem !default;
$main-logo-max-width:                       14.875rem !default;
$mobile-logo-margin-top:                   .5rem !default;
$mobile-logo-margin-bottom:                .5rem !default;
$mobile-logo-max-height:                    2.5rem !default;

$pre-background:                            $gray-100 !default;
$pre-padding:                               4px 10px !default;
$pre-margin-top:                            24px !default;

// box shadow
$box-shadow-0:                              0 transparent !default;

// block title
$blocktitle-padding-left:                   $one-rem !default;
$blocktitle-padding-right:                  $one-rem !default;
$blocktitle-padding-top:                    $quarter-rem !default;
$blocktitle-padding-bottom:                 $quarter-rem !default;
$blocktitle-padding-top2:                   $half-rem !default;
$blocktitle-padding-bottom2:                $half-rem !default;
$blocktitle-padding-bottom3:               .3rem !default;
$blocktitle-padding-bottom5:                .45rem !default;

$blocktitle-margin-right:                   $one-rem !default;
$blocktitle-margin-bottom:                  $one-half-rem !default;

$blocktitle-space-top:                      45% !default;
$blocktitle-space-left-mobile:              5px !default;
$blocktitle-space-right-mobile:             5px !default;

$blocktitle-arrow-height:                   12px !default;
$blocktitle-max-width:                      100% !default;
$blocktitle-border-default:                 2px !default;
$blocktitle-border-color:                   $border-color !default;

// card full
$card-full-padding-top:                     1rem !default;
$card-img-min-height:                       3rem !default;
$card-list-padding:                        .5rem !default;
$card-list-margin-bottom:                  .5rem !default;
$card-list-before-font-size:               $font-size-base * .8 !default;
$card-list-before-margin-right:            .5rem !default;
$card-list-before-margin-left:             -.5rem !default;
      
$card-horizontal-padding:                   1.25rem !default;
$framed-card-full-padding:                  1.25rem !default;
$framed-card-full-padding-top:              1rem !default;
$card-full-padding-no-gutters:              1.25rem !default;
$sub-card-margin-top:                       1rem !default;

// Main menu
$font-size-top-menu:                       $font-size-base * .875 !default;
$main-nav-font-size:                       $font-size-base * .875 !default;
$main-nav-spacing:                         .035rem !default;

// show back top navbar
$showbacktop-bg:                            rgba($white, .95) !default;
$showbacktop-space-top:                    -54px !default;
$showbacktop-logo-img-max-width:            10rem !default;
$showbacktop-logo-img-max-height:           2.3rem !default;
$showbacktop-logo-img-margin-bottom:       .5rem !default;
$showbacktop-logo-img-margin-right:        1rem !default;
 
// dark skin
$showbacktop-dark-background:               rgba($black, .95) !default;
$showbacktop-dark-space-top:               -58px !default;

// mega menu
$mega-item-padding-left:                    1.5rem !default;
$mega-item-padding-right:                   1.5rem !default;
$mega-item-active:                          $gray-100 !default;

// dropdown submenu
$dropdown-item-hover:                       $primary !default;
$dropdown-toggle-after:                     2rem !default;
$dropdown-dark-bg:                          rgba($dark, .85) !default;

$dropdown-submenu-top:                     -11px !default;
$dropdown-submenu-left:                     100% !default;
$dropdown-submenu-margin-left:              0 !default;
$dropdown-submenu-margin-right:            0 !default;
$dropdown-submenu-mini:                    .6rem !default;
$dropdown-submenu-mobile-left:             .2rem !default;
$dropdown-submenu-mobile-margin-left:       1.5rem !default;

$toggle-mobile-left-after:                  auto !default;
$toggle-mobile-right-after:                 2rem !default;
$border-right-sm:                           1px !default;
$light-nav-hover-link:                      rgba($black, .9) !default;

$mobile-navlink-right:                      1rem !default;
$mobile-navlink-left:                       1rem !default;
$mobile-navlink-left2:                      0 !default;

// ticker
$ticker-margin:                             10px !default;

// social fly
$social-fly-top:                            35% !default;
$social-fly-padding-top:                    4.5rem !default;
$social-fly-left:                           1rem !default;
$social-fly-lg-left:                        2rem !default;
$social-fly-bottom:                        .5rem !default;

// font awesome
$font-a-family:                             "Font Awesome 5 Free" !default;
$font-a-brand-family:                       "Font Awesome 5 Brands" !default;
$font-a-size:                              .8rem !default;
$font-a-margin-right:                      .8rem !default;
$font-a-margin-left:                       .8rem !default;

// vertical tabs
$vertical-navtabs-right:                    1.5rem !default;
$vertical-navtabs-border-right:             1px !default;
$vertical-navlink-border:                   1px !default;
$vertical-navlink-radius:                   0 !default;
$vertical-navlink-color:                    $primary !default;
$vertical-navlink-bg-color:                 $white !default;
$vertical-navlink-border-color:             $border-color $white $border-color $white !default;
$vertical-content-height:                   10.3125rem !default;
$vertical-li-margin-right:                 -1px !default;

// nav tabs
$sidetabs-color:                            $white !default;
$nav-tabs-background:                       $primary !default; 
$nav-tabs-border:                           $primary !default;
$nav-tabs-border-hover:                     $border-color $border-color $white !default;
$nav-tabs-border-active:                    $primary !default; 

// Hamburger
$hamburger-width:                           1.5rem !default;
$hamburger-height:                          1.5rem !default;
$hamburger-rotate:                          rotate(0deg) !default;
$hamburger-transition:                     .5s !default;
$hamburger-icon-height:                     2px !default;
$hamburger-icon-width:                      100% !default;
$hamburger-icon-background:                 $white !default;
$hamburger-transition2:                    .25s !default;

$hamburger-child-1-top:                     0 !default;
$hamburger-child-1-top-open:               .5rem !default;
$hamburger-child-2-top:                    .6rem !default;
$hamburger-child-4-top:                     1.2rem !default;
$hamburger-child-4-top-open:                1rem !default;
$hamburger-child-2-rotate:                  rotate(45deg) !default;
$hamburger-child-3-rotate:                  rotate(-45deg) !default;

// breadcrumb
$breadcrumb-padding-right-1:               .5rem !default;
$breadcrumb-padding-right-2:               .5rem !default;
$breadcrumb-color:                          $color_p !default;

// lazyload images
$img-wrapper-bg:                            $gray-100 !default;
$img-wrapper-gradient:                      linear-gradient(to left, $gray-100 0%, $gray-400 15%, $gray-100 40%, $gray-100 100%) !default;
$img-wrapper-size:                          400% 700% !default;
$img-wrapper-animation:                     1.5s !default;
$b-lazy-transition:                         500ms ease-in-out !default;

// Layout boxed and framed
$boxed-framed-bg:                           $gray-100 !default;
$boxed-wrapper-bg:                          $white !default;
$boxed-framed-box-shadow:                   0 0 3px $border-color !default;
$framed-main-bg:                            rgba($white, .7) !default;
$boxed-framed-mobile-width:                 100% !default;
$boxed-framed-tabs-width:                   90% !default;
$boxed-framed-desktop-width:                1200px !default;
$boxed-framed-desktop-padding:             .5rem 0rem !default;

// breaking news
$navigation-box-top:                       .8rem !default;
$navigation-box-right:                     .8rem !default;
$navigation-box-width:                      2rem !default;
$breaking-box-padding-top:                 .5rem !default;
$breaking-box-padding-bottom:              .5rem !default;
$breaking-box-line-height:                  2.25rem !default;

$breaking-caret-top:                        0 !default;
$breaking-caret-right:                     -15px !default;
$breaking-caret-b-top:                      20px !default;
$breaking-caret-b-bottom:                   20px !default;
$breaking-caret-b-left:                     15px solid $primary !default;

// Big Grid
$ratio-padding-05 :                        .125rem !default;
$ratio-padding-1 :                          1rem !default;
$ratio-padding-15 :                         15px !default;

// back to top
$backtotop-transition-1:                    opacity .3s 0s, visibility 0s .3s !default;
$backtotop-transition-2:                    opacity .3s 0s, visibility 0s 0s !default;
$backtotop-no-touch:                        $primary !default;

// mobile menu
$mobile-menu-transition:                    all 0.5s ease !default;
$mobile-menu-width:                         17.5rem !default;
$mobile-menu-padding:                       1rem !default;
$mobile-menu-a-padding:                    .75rem 0 !default;

$mobile-push-transition:                    all 0.5s ease !default;
$mobile-push-left:                         -17.5rem !default;
$mobile-push-right:                        -17.5rem !default;

$mobile-before-open-color:                  rgba($black, .6) !default;
$mobile-backmenu-open-color:                $white !default;
$mobile-before-padding:                     1rem !default;

$backmenu-left-open:                        17.5rem !default;
$backmenu-right-open:                       17.5rem !default;
$backmenu-open-padding:                     1rem !default;
$backmenu-social-top:                       1rem !default;

$sidenav-margin-bottom:                     1rem !default;
$menu-mobile-a:                            .875rem !default;
$sidenav-level1-p-left:                    .5rem !default;
$sidenav-level2-p-left:                     1rem !default;
$sidenav-level1-b-top:                      $border-color 1px solid !default;
$sidenav-level2-b-top:                      $border-color 1px solid !default;

// post page
$post-content-margin-bottom:                1rem !default;
$post-li-margin-left:                       0 !default;
$post-li-line-height:                       24px !default;
$post-before-arrow-right:                  .8rem !default;
$post-before-arrow-size:                   .8rem !default;
$post-content-line-height:                  1.75 !default;
$post-font-style:                          Helvetica;
$post-font-size:                            $font-size-base * 1 !default;
$post-content-img-bottom:                   1rem !default;
$children-padding-left:                     2rem !default;
$max-editor:                                40rem !default;

// align
$align-left-margin-right:                   1.2rem !default;
$align-left-margin-top:                     4px !default;
$align-right-margin-left:                   1.2rem !default;
$align-right-margin-top:                    4px !default;

// figure
$figure-caption-font-size:                 $font-size-base * .6875 !default;
$figure-caption-font-style:                 italic !default;
$figure-caption-line-height:                1rem !default;
$figure-caption-padding:                    5px 0 !default;

// blockquote
$u-blockquote-color:                        $gray-200 !default;
$u-blockquote-font-size:                    $font-size-base * 2 !default;
$u-blockquote-margin-left:                  3.5rem !default;

// dropcaps
$dropcaps-margin:                           .7rem .7rem 0 0 !default;
$dropcaps-padding:                          2px 10px 5px !default;
$dropcaps-font-size:                        $font-size-base * 3.5 !default;
$dropcaps-line-height:                      3.5rem !default;
$dropcaps-min-width:                        3.5rem !default;
$dropcaps-min-height:                       3.5rem !default;
$dropcaps-rounded-font-size:                $font-size-base * 2.75 !default;
$dropcaps-rounded-min-width:                4rem !default;
$dropcaps-one-font-size:                    $font-size-base * 5 !default;
$dropcaps-one-padding:                      0 !default;

// ratings
$ratings-font-size:                         $font-size-base * .8 !default;
$ratings-margin-top:                       .5rem !default;
$ratings-margin-bottom:                    .5rem !default;

// suggestion box
$sugestion-bottom:                          4rem !default;
$sugestion-right:                          -18.3rem !default;
$sugestion-width:                           18.3rem !default;
$sugestion-padding:                         10px 20px 20px !default;
$sugestion-border:                          $border-color 1px solid !default;
$sugestion-border-top:                      $primary 4px solid !default;
$sugestion-close-padding:                   1px 8px 3px !default;
$sugestion-close-font-size:                 $font-size-base * 1.2 !default;

// comment
$comment-list-top:                          2rem !default;
$comment-article-b-bottom:                  $border-color 1px dashed !default;
$comment-article-p-bottom:                  1rem !default;
$subcomment-margin-left:                    6rem !default;
$sub-subcomment-margin-left:                6rem !default;
$sub-subcomment-mobile-margin-left:         0 !default;
$comment-p:                                 0 !default;

// archive
$rotate-downs:                              rotate(90deg) !default;
$collapse-rotate-downs:                     rotate(0deg) !default;

// tags
$tags-list-padding:                         0rem 0.5rem !default;
$tags-list-border:                          $border-color 1px solid !default;
$tags-list-right:                           0.25rem !default;
$tags-list-bottom:                          0.25rem !default;
$tags-list-bg-hover:                        $primary !default;
$tags-list-color-hover:                     $white !default;

// vertical slider
$bignav-li-bg-hover:                        $primary !default;
$bignav-li-color-hover:                     $white !default;
$bignav-indicator-p-right:                  15px !default;
$bignav-indicator-p-left:                   15px !default;
$indicator-top:                             1rem !default;
$indicator-right:                           1rem !default;

$slider-caption-color:                      $white !default;
$slider-caption-bg:                         rgba($dark, .5) !default;
$slider-caption-font:                       1rem !default;
$slider-caption-padding:                    1.333rem !default;
$slider-caption-radius:                     2px !default;

// member area
$sidebar-menu-border:                       1px solid $border-color !default;
$sidebar-menu-padding:                      1rem !default;
$sidebar-menu-bg:                           $gray-100 !default;

$sidebar-item-padding:                      .75rem 1.25rem;
$sidebar-item-m-bottom:                     0.5rem !default;
$sidebar-item-border:                       1px solid transparent !default;

$sidebar-item-active-border:                1px solid $border-color !default;
$sidebar-item-active-bg:                    $white !default;
$sidebar-item-m-right:                      -1px !default;

$sidebar-item-hover-bg:                     $white !default;

$icon-big-font:                             3rem !default;
$number-p-font:                             1rem !default;
$numbers-font:                              2rem !default;
$card-footer-padding:                       0 !default;
$card-footer-height:                        30px !default;

$statistic-li-padding:                      0.25rem 0;
$member-item-bg-hover:                      $gray-100 !default;

$icon-notif-min-width:                      25px !default;
$icon-notif-border-radius:                  5rem !default;
$icon-notif-font:                           1rem !default;
$icon-notif-bg:                             $red !default;
$icon-notif-color:                          $white !default;

$side-notif-padding:                        7px !default;
$side-notif-font:                           .8rem !default;

$table-odd:                                 $gray-100 !default;
$sidebar-item-mobile-active-b-right:         1px solid $border-color !default;
$sidebar-item-mobile-active-m-left:         .25rem !default;
$sidebar-item-mobile-active-m-right:        .25rem !default;

//footer widget
$widget-title-padding:                      .5rem 0;
$widget-title-margin-bottom:                1rem !default;

// messages
$unread-bg:                                 $gray-100 !default;
$messages-box-max-height:                   28rem !default;
$online-circle-radius:                      5rem !default;
$online-circle-width:                       5rem !default;
$online-circle-height:                      5rem !default;

$messages-right-margin:                     0px 5px;
$messages-list-p-padding:                   15px !default;
$messages-list-p-border:                    $border-color 1px solid !default;
$messages-header-bottom:                    .5rem !default;
$text-editor-min-height:                    18rem !default;
$text-editor-font-size:                     1rem !default;

$dot-bg:                                    rgba($white, .8) !default;

//Sidebar
$sidebar-padding-left:                      30px !default;
$sidebar-padding-right:                     30px !default;
$sidebar-link-font-size:                    $font-size-base * 1 !default;
$sidebar-link-padding:                      .5rem 0 !default;

//footer widget
$widget-title-padding:                      .5rem 0 !default;
$widget-title-margin-bottom:                1.5rem !default;

// maps widget
$maps-padding-bottom:                       75% !default;

// social
$social2-width-li:                          49% !default;
$social2-width-li-md:                       100% !default;
$social2-li-a-bottom:                       .5rem !default;
$social2-li-a-font:                         14px !default;
$social2-li-a-height:                       34px !default;
$social2-li-a-radius:                       0 !default;

$social-a-before-width:                     34px !default;
$social-a-before-height:                    34px !default;
$social-a-before-font:                      16px !default;
$social-a-before-margin:                    0 10px 0 0 !default;
$social-a-before-bg:                        rgba($white, .1) !default;

// popular post
$post-number-li-margin:                     .5rem !default;
$post-number-li-padding:                    .5rem .5rem 1rem !default;
$font-size-post-number:                     2rem !default;

$icon-stack-border:                          $primary 3px solid !default;
$icon-stack-border-radius:                   3rem !default;
$icon-stack-height:                          2.5em !default;
$icon-stack-line-height:                     2em !default;
$icon-stack-width:                           2.5em !default;
$icon-stack-2-padding:                       0.8rem !default;
$icon-stack-2-width:                         4rem !default;
$icon-stack-2-height:                        4rem !default;

$post-number-a-color:                        $gray-400 !default;
$post-number-a-width:                        3rem !default;
$post-number-a-right:                        .5rem !default;
$post-number-a-line:                         2rem !default;

// weather
$weather-icon-width:                         3rem !default;
$weather-span-left:                          2.8rem !default;
$weather-small-width:                        2rem !default;

// score
$score-title-padding:                        .3rem 0 !default;
$score-title-bg:                             $gray-100 !default;
$score-li-padding:                           .3rem 0 !default;
$score-li-hover-bg:                          $gray-100 !default;

// timeline
$timeline-post-before-background:            $border-color !default;
$timeline-post-before-left:                  1.5rem !default;
$timeline-post-before-width:                 2px !default;

$timeline-post-li-margin:                    1rem 0 !default;
$timeline-post-li-padding-left:             .875rem !default;
$timeline-post-li-padding-right:            .875rem !default;
$timeline-post-li-before-background:         $white !default;
$timeline-post-li-before-border:             3px solid $border-color !default;
$timeline-post-li-before-left:               1rem !default;
$timeline-post-li-before-width:              1rem !default;
$timeline-post-li-before-height:             1rem !default;
$timeline-post-hover-before-background:      $primary !default;
$timeline-post-date-padding-left:            1.5rem !default;
$timeline-post-date-color:                   $primary !default;

// playlist video
$playlist-title-max-height:                  307px !default;
$playlist-title-li-padding:                  5px 0px !default;
$playlist-title-li-hover-background:         $gray-900 !default;
$playlist-title-li-active-background:        $gray-900 !default;
$playlist-title-li-active-border:            $primary 1px solid !default;
$playlist-title-li-thumb-margin-right:       15px !default;
$playlist-title-li-thumb-border:             2px solid transparent !default;

// avatar
$avatar-margin:                              .5rem auto !default;
$card-avatar-border:                         $white 5px solid !default;
$avatar-rounder-radius:                      3rem !default;
$avatar-xs-width:                            1.5625rem !default;
$avatar-xs-height:                           1.5625rem !default;
$avatar-sm-width:                            3.125rem !default;
$avatar-sm-height:                           3.125rem !default;
$avatar-md-width:                            4.6875rem !default;
$avatar-md-height:                           4.6875rem !default;
$avatar-lg-width:                            6.25rem !default;
$avatar-lg-height:                           6.25rem !default;
$avatar-xl-width:                            10rem !default;
$avatar-xl-height:                            10rem !default;
$nav-avatar-padding:                         0rem 1rem !default;

// progress if scroll down
$progress-scroll-bg:                        rgba($white, .5) !default;
$progress-1-height:                         2px !default;
$progress-2-height:                         4px !default;
$progress-3-height:                         5px !default;
$progress-color:                            $primary !default;
$progress-bg-value:                         $primary !default;

// background images
$bg-cover-color:                            $white !default;
$bg-cover-1:                                url(../img/background/bg1.jpg) !default;
$bg-cover-2:                                url(../img/background/bg2.jpg) !default;
$bg-cover-3:                                url(../img/background/bg3.jpg) !default;

$bg-repeat-1:                               url(../img/background/pattern1.png) !default;
$bg-repeat-2:                               url(../img/background/pattern2.png) !default;
$bg-repeat-3:                               url(../img/background/pattern3.png) !default;

$bg-pattern-repeat:                         url(../img/background/pattern4.png) !default;
$bg-pattern-repeat-dark:                    url(../img/background/pattern5.png) !default;

$bg-footer:                                 url(../img/background/bg-footer.jpg) !default;
$bg-footer-before:                          rgba($dark, .96) !default;

// background video
$background-video-background:               $black !default;
$background-video-height:                   75vh !default;
$background-video-min-height:               25rem !default;

$background-video-height-sm:                30vh !default;
$background-video-height-sm-up:             40vh !default;
$background-video-min-height-sm:            20rem !default;
$background-video-min-height-md:            25rem !default;
$background-video-height-sm-md:             50vh !default;
$background-video-height-sm-lg:             75vh !default;

$mini-line-margin:                          1rem auto !default;
$mini-line-width:                           6rem !default;
$mini-line-border-top:                      $primary 3px solid !default;

// zoom
$zoom-media-transition:                     all .2s linear !default;
$zoom-media-transform:                      scale(1.1) !default;

// space
$space-one:                                 1rem !default;
$space-two:                                 2rem !default;
$space-three:                               3rem !default;
$space-four:                                4rem !default;

$space-percent-one:                         10% !default;
$space-percent-two:                         20% !default;
$space-percent-three:                       30% !default;
$space-percent-four:                        40% !default;
$space-percent-five:                        50% !default;

// border width
$border-one:                                1px !default;
$border-two:                                2px !default;
$border-three:                              3px !default;
$border-four:                               4px !default;
$border-five:                               5px !default;

$border-smooth:                             rgba($white, .12) !default;
$border-child:                              1px solid $border-color !default;
$border-smooth-light:                       $gray-400 !default;

$shadow-color:                              rgba($black, .1) !default;

// margin extra
$margin-05:                                .125rem !default;
$margin-six:                                4.5rem !default;
$margin-seven:                              6rem !default;
$margin-eight:                              7.5rem !default;
$margin-nine:                               9rem !default;

// margin top minus
$margin-minus:                             -2.5rem !default;
$margin-minus-sm:                          -1rem !default;
$margin-minus-md:                          -3rem !default;
$margin-minus-lg:                          -6rem !default;

// gap
$gap-05:                                    .5rem !default;;
$gap-075:                                    .75rem !default;;                                     
$gap-1:                                     1rem !default;;
$gap-15:                                    1.5rem !default;;
$gap-2:                                     2rem !default;;
$gap-25:                                    2.5rem !default;;
$gap-3:                                     3rem !default;;
$gap-4:                                     4rem !default;;

// padding extra
$padding-05:                               .125rem !default;
$padding-six:                               4.5rem !default;
$padding-seven:                             6rem !default;
$padding-eight:                             7.5rem !default;
$padding-nine:                              9rem !default;

// mobile
$row-mobile-left:                          -5px !default;
$row-mobile-right:                         -5px !default;
$column-mobile-left:                        5px !default;
$column-mobile-right:                       5px !default;

// five grid
$min-height-grid:                           1px !default;
$padding-right-grid:                        15px !default;
$padding-left-grid:                         15px !default;

// dark skin
$dark-skin-color:                           $gray-600 !default;
$dark-skin-background-color:                $dark !default;
$border-dark-color:                         $gray-700 !default;